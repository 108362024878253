import React from 'react'
import styled from 'styled-components'

import { IImage } from '../../types/custom'
import FadeInSection from '../fade-in'

interface IProps {
  logos: IImage[]
}

export default function LogosList({ logos }: IProps) {
  return (
    <FadeInSection>
      <Container>
        {logos.map((image, index) => (
          <img alt={image.alt} key={index} src={image.url} />
        ))}
      </Container>
    </FadeInSection>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 12px;
  margin: 40px 0 32px 0;
  width: 100%;
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(3, 1fr);
  }

  & > img {
    max-width: 100%;
  }
`
