import { TemplatePodcastsDataQuery } from '../../../gatsby-types'

import { IPodcastsContent } from './types'

export function transformPodcastsNode(node: TemplatePodcastsDataQuery['prismicPodcasts']): IPodcastsContent {
  if (!node) throw new Error('Invalid podcast node.')

  const { uid, data } = node
  if (!uid || !data) throw new Error('Invalid podcast node.')

  const { name, title, description, parentdocument } = data
  if (
    !name ||
    !name.text ||
    !title ||
    !title.text ||
    !description ||
    !description.text ||
    !parentdocument ||
    !parentdocument.uid ||
    !parentdocument.document ||
    !parentdocument.document.data ||
    !parentdocument.document.data.name ||
    !parentdocument.document.data.name.text
  ) {
    throw new Error('Invalid podcast node.')
  }

  return {
    uid,
    name: name.text,
    title: title.text,
    description: description.text,
    parent: {
      uid: parentdocument.uid,
      name: parentdocument.document.data.name.text,
    },
  }
}
