import { graphql } from 'gatsby'
import { usePrismicPreview } from 'gatsby-source-prismic'
import React, { useEffect, useState } from 'react'

import { PreviewDataQuery } from '../../gatsby-types'
import Layout from '../layouts/default'
import NotFound from '../models/404/template'
import About from '../models/about/template'
import BrandHome from '../models/brand-home/template'
import BlogPost from '../models/blog-post/template'
import Catalog from '../models/catalog/template'
import Career from '../models/career/template'
import Contact from '../models/contact/template'
import ContactSante from '../models/contact-sante/template'
import Home from '../models/home/template'
import JobOffer from '../models/job-offer/template'
import News from '../models/news/template'
import Podcasts from '../models/podcasts/template'
import linkResolver from '../utils/link-resolver'
import Press from '../models/press/template'
import SimplePage from '../models/simple-page/template'

interface IProps {
  data: PreviewDataQuery
}

export default function PreviewPage({ data: { allPrismicBlogPost } }: IProps) {
  const [loading, setLoading] = useState(true)

  const { isPreview, previewData, path } = usePrismicPreview({
    repositoryName: 'mysgwebsite',
    linkResolver: () => linkResolver,
  })
  console.log('previewData', previewData)

  useEffect(() => {
    if (!isPreview || !previewData) return

    setLoading(false)
  }, [isPreview, previewData, path])

  if (!isPreview) return <div>Not a preview!</div>

  if (loading) return <div>Loading preview...</div>

  if (previewData) {
    const {
      prismicHeader,
      prismicFooter,
      prismicHome,
      prismicBrandHome,
      prismicNews,
      prismicPodcasts,
      prismicCatalog,
      prismicBlogPost,
      prismicContact,
      prismicPress,
      prismicAbout,
      prismicCareer,
      prismicJobOffer,
      prismicSimplePage,
      prismic404,
      prismicContactSante,
    } = previewData

    const previewDataWithAllPrismicBlogPost = { ...previewData, allPrismicBlogPost }

    // Layout
    if (prismicHeader) return <Layout headerPreviewData={previewData} title="My Serious Game" />
    if (prismicFooter) return <Layout footerPreviewData={previewData} title="My Serious Game" />

    // Pages
    if (prismicHome) return <Home data={previewDataWithAllPrismicBlogPost} />
    if (prismicBrandHome) return <BrandHome data={previewData} />
    if (prismicNews) return <News data={previewDataWithAllPrismicBlogPost} />
    if (prismicCatalog) return <Catalog data={previewDataWithAllPrismicBlogPost} />
    if (prismicPodcasts) return <Podcasts data={previewDataWithAllPrismicBlogPost} />
    if (prismicBlogPost) return <BlogPost data={previewDataWithAllPrismicBlogPost} />
    if (prismicContact) return <Contact data={previewData} />
    if (prismicContactSante) return <ContactSante data={previewData} />
    if (prismicPress) return <Press data={previewDataWithAllPrismicBlogPost} />
    if (prismicAbout) return <About data={previewData} />
    if (prismicCareer) return <Career data={{ ...previewData, allPrismicJobOffer: { nodes: [] } }} />
    if (prismicJobOffer) return <JobOffer data={previewData} />
    if (prismicSimplePage) return <SimplePage data={previewData} />
    if (prismic404) return <NotFound data={previewDataWithAllPrismicBlogPost} />
  }

  return <div>Document has no preview</div>
}

export const query = graphql`
  query PreviewData {
    allPrismicBlogPost(
      sort: { order: DESC, fields: data___postdate }
      filter: { data: { parentdocument: { uid: { eq: "actualites" } } } }
      limit: 3
    ) {
      nodes {
        uid
        data {
          parentdocument {
            uid
            document {
              ... on PrismicCatalog {
                data {
                  name {
                    text
                  }
                }
              }
              ... on PrismicNews {
                data {
                  name {
                    text
                  }
                }
              }
              ... on PrismicPodcasts {
                data {
                  name {
                    text
                  }
                }
              }
            }
          }
          postcategory
          postsecondarycategories {
            category
          }
          posttemplate
          posttitle {
            text
          }
          postdescription {
            html
          }
          postmainimage {
            alt
            url
          }
          postthumbnailimage {
            alt
            url
          }
          postintroductiontext {
            html
          }
          postdate(locale: "fr", formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`

PreviewPage.query = query
