/* eslint-disable complexity */
import { TemplateContactDataQuery } from '../../../gatsby-types'

import { IContactContent } from './types'

export function transformContactNode(node: TemplateContactDataQuery['prismicContact']): IContactContent {
  if (!node) throw new Error('Invalid contact node.')

  const { uid, data } = node
  if (!uid || !data) throw new Error('Invalid contact node.')

  const { name, title, description, parentdocument, body } = data

  if (
    !name ||
    !name.text ||
    !title ||
    !title.text ||
    !description ||
    !description.text ||
    !parentdocument ||
    !parentdocument.uid ||
    !parentdocument.document ||
    !parentdocument.document.data ||
    !parentdocument.document.data.name ||
    !parentdocument.document.data.name.text ||
    !body ||
    !body[0] ||
    !body[0].items
  ) {
    console.log('name', name, title, description)
    console.log('parentdocument', parentdocument)
    console.log('body', body)
    throw new Error('Invalid contact node.')
  }

  return {
    uid,
    name: name.text,
    title: title.text,
    description: description.text,
    sante: false,
    parent: {
      uid: parentdocument.uid,
      name: parentdocument.document.data.name.text,
    },
    footerCards: body[0].items.map((item) => {
      if (!item) throw new Error('Invalid contact node.')

      const {
        footercardtitle,
        footercardsubtitle,
        footercardtext,
        footercardlinklabel,
        footercardlinkurl,
        footercardbackground,
      } = item

      if (
        !footercardtitle ||
        !footercardtitle.text ||
        !footercardsubtitle ||
        !footercardsubtitle.text ||
        !footercardtext ||
        !footercardtext.text ||
        !footercardlinklabel ||
        !footercardlinklabel.text ||
        !footercardlinkurl ||
        !footercardlinkurl.url ||
        !footercardbackground ||
        !footercardbackground.url
      ) {
        throw new Error('Invalid contact footer_card slice.')
      }

      return {
        title: footercardtitle.text,
        subtitle: footercardsubtitle.text,
        text: footercardtext.text,
        link: { label: footercardlinklabel.text, url: footercardlinkurl.url },
        background: { alt: '', url: footercardbackground.url },
      }
    }),
  }
}
