import { TemplateCatalogDataQuery } from '../../../gatsby-types'

import { ICatalogContent } from './types'

export function transformCatalogNode(node: TemplateCatalogDataQuery['prismicCatalog']): ICatalogContent {
  if (!node) throw new Error('Invalid catalog node.')

  const { uid, data } = node
  if (!uid || !data) throw new Error('Invalid catalog node.')

  const { name, title, description, parentdocument, logos } = data
  if (
    !name ||
    !name.text ||
    !title ||
    !title.text ||
    !description ||
    !description.text ||
    !logos ||
    !parentdocument ||
    !parentdocument.uid ||
    !parentdocument.document ||
    !parentdocument.document.data ||
    !parentdocument.document.data.name ||
    !parentdocument.document.data.name.text
  ) {
    throw new Error('Invalid catalog node.')
  }

  return {
    uid,
    name: name.text,
    title: title.text,
    description: description.text,
    logos: logos.map((logo) => {
      if (!logo || !logo.image || !logo.image.url) {
        throw new Error('Invalid logos on catalog node.')
      }

      return { alt: logo.image.alt || '', url: logo.image.url }
    }),
    parent: {
      uid: parentdocument.uid,
      name: parentdocument.document.data.name.text,
    },
  }
}
