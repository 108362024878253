import { TemplateSimplePageDataQuery } from '../../../gatsby-types'

import { ISimplePageContent } from './types'

export function transformSimplePageNode(node: TemplateSimplePageDataQuery['prismicSimplePage']): ISimplePageContent {
  if (!node) throw new Error('Invalid podcast node.')

  const { uid, data } = node
  if (!uid || !data) throw new Error('Invalid podcast node.')

  const { name, title, content, parentdocument } = data
  if (
    !name ||
    !name.text ||
    !title ||
    !title.text ||
    !content ||
    !content.html ||
    !parentdocument ||
    !parentdocument.uid ||
    !parentdocument.document ||
    !parentdocument.document.data ||
    !parentdocument.document.data.name ||
    !parentdocument.document.data.name.text
  ) {
    throw new Error('Invalid podcast node.')
  }

  return {
    uid,
    name: name.text,
    title: title.text,
    content: content.html,
    parent: {
      uid: parentdocument.uid,
      name: parentdocument.document.data.name.text,
    },
  }
}
