/* eslint-disable complexity */
import { Template404DataQuery } from '../../../gatsby-types'

import { INotFoundContent } from './types'

export function transform404Node(node: Template404DataQuery['prismic404']): INotFoundContent {
  if (!node) throw new Error('Invalid 404 node.')

  const { data } = node
  if (!data) throw new Error('Invalid 404 node.')

  const {
    name,
    title,
    text,
    leftlinklabel,
    leftlinkurl,
    rightlinklabel,
    rightlinkurl,
    banner,
    subtitle,
    parentdocument,
    body,
  } = data

  if (
    !name ||
    !name.text ||
    !title ||
    !title.text ||
    !text ||
    !text.text ||
    !leftlinklabel ||
    !leftlinklabel.text ||
    !leftlinkurl ||
    !leftlinkurl.url ||
    !rightlinklabel ||
    !rightlinklabel.text ||
    !rightlinkurl ||
    !rightlinkurl.url ||
    !banner ||
    !banner.url ||
    !subtitle ||
    !subtitle.text ||
    !parentdocument ||
    !parentdocument.uid ||
    !parentdocument.document ||
    !parentdocument.document.data ||
    !parentdocument.document.data.name ||
    !parentdocument.document.data.name.text ||
    !body ||
    !body[0] ||
    !body[0].items
  ) {
    throw new Error('Invalid 404 node.')
  }

  return {
    name: name.text,
    title: title.text,
    text: text.text,
    parent: {
      uid: parentdocument.uid,
      name: parentdocument.document.data.name.text,
    },
    banner: { alt: banner.alt || '', url: banner.url },
    leftLink: { label: leftlinklabel.text, url: leftlinkurl.url },
    rightLink: { label: rightlinklabel.text, url: rightlinkurl.url },
    subtitle: subtitle.text,
    footerCards: body[0].items.map((item) => {
      if (!item) throw new Error('Invalid 404 node.')

      const {
        footercardtitle,
        footercardsubtitle,
        footercardtext,
        footercardlinklabel,
        footercardlinkurl,
        footercardbackground,
      } = item

      if (
        !footercardtitle ||
        !footercardtitle.text ||
        !footercardsubtitle ||
        !footercardsubtitle.text ||
        !footercardtext ||
        !footercardtext.text ||
        !footercardlinklabel ||
        !footercardlinklabel.text ||
        !footercardlinkurl ||
        !footercardlinkurl.url ||
        !footercardbackground ||
        !footercardbackground.url
      ) {
        throw new Error('Invalid contact node.')
      }

      return {
        title: footercardtitle.text,
        subtitle: footercardsubtitle.text,
        text: footercardtext.text,
        link: { label: footercardlinklabel.text, url: footercardlinkurl.url },
        background: { alt: '', url: footercardbackground.url },
      }
    }),
  }
}
