import {
  PrismicBlogPost,
  PrismicCatalog,
  PrismicContact,
  PrismicHome,
  PrismicContactSante,
  PrismicNews,
} from '../../gatsby-types'

import { linkGenerator, PrismicDocumentType } from './link-generator'

export default function linkResolver(
  doc: PrismicHome | PrismicCatalog | PrismicNews | PrismicBlogPost | PrismicContact | PrismicContactSante
) {
  const { type, uid, data } = doc

  switch (type) {
    case 'home':
      return linkGenerator(PrismicDocumentType.HOME)
    case 'home_english':
      return linkGenerator(PrismicDocumentType.HOME_ENGLISH)
    case 'contact_english':
      return linkGenerator(PrismicDocumentType.CONTACT_ENGLISH)
    case 'about':
    case 'catalog':
    case 'news':
    case 'podcasts':
    case 'contact':
    case 'contact_sante':
    case 'contact_lms':
    case 'press':
    case 'career':
    case 'simple_page':
    case 'brand_home': {
      return linkGenerator(PrismicDocumentType.PAGE, uid)
    }
    case 'blog_post': {
      return linkGenerator(PrismicDocumentType.BLOG_POST, uid, data?.parentdocument?.uid)
    }
    case 'job_offer': {
      return linkGenerator(PrismicDocumentType.JOB_OFFER, uid, data?.parentdocument?.uid)
    }
    default:
      return null
  }
}
