import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { TemplateContactSanteDataQuery } from '../../../gatsby-types'
import { getTitle } from '../../utils/prismic'
import Breadcrumb from '../../components/common/breadcrumb'
import Layout from '../../layouts/default'
import ContactForm from '../../components/forms/contact'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import FooterCards from '../../components/common/slices/footer-cards'
import { transformSeo } from '../../utils/seo-transformer'

import { transformContactSanteNode } from './transformer'

interface IProps {
  data: TemplateContactSanteDataQuery
  path?: string
}

export default function ContactSante({ data: { prismicContactSante }, path }: IProps) {
  const pageContent = transformContactSanteNode(prismicContactSante)
  const seoContent =
    prismicContactSante && prismicContactSante.data && prismicContactSante.data.seo && prismicContactSante.data.seo[0]
      ? transformSeo(prismicContactSante.data.seo[0])
      : undefined

  const { uid, name, title, description, sante, parent, footerCards } = pageContent

  return (
    <Layout path={path} seoContent={seoContent} title={getTitle(name)}>
      <Container>
        <Breadcrumb
          links={[
            { label: parent.name, url: linkGenerator(PrismicDocumentType.HOME) },
            { label: name, url: linkGenerator(PrismicDocumentType.PAGE, uid) },
          ]}
        />
        <h2>{title}</h2>
        <p>{description}</p>
        <ContactForm isHealthcare={sante} />
        <FooterCards content={footerCards} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query TemplateContactSanteData {
    prismicContactSante {
      uid
      data {
        parentdocument {
          uid
          document {
            ... on PrismicHome {
              data {
                name {
                  text
                }
              }
            }
            ... on PrismicBrandHome {
              data {
                name {
                  text
                }
              }
            }
          }
        }
        name {
          text
        }
        title {
          text
        }
        description {
          text
        }
        sante
        body {
          ... on PrismicContactSanteBodyFooterCards {
            slice_type
            items {
              footercardtitle {
                text
              }
              footercardsubtitle {
                text
              }
              footercardtext {
                text
              }
              footercardlinklabel {
                text
              }
              footercardlinkurl {
                url
              }
              footercardbackground {
                url
              }
            }
          }
        }
        seo {
          ... on PrismicContactSanteSeoMeta {
            id
            primary {
              metatitle {
                text
              }
              metadecription {
                text
              }
              metaogtype {
                text
              }
              metaogtitle {
                text
              }
              metaogdescription {
                text
              }
              metaogimage {
                url
              }
              metaogurl {
                text
              }
              metaogsitename {
                text
              }
              metatwittercard {
                text
              }
              metatwittertitle {
                text
              }
              metatwitterdescription {
                text
              }
              metatwitterimage {
                url
              }
              canonicallink {
                text
              }
            }
          }
        }
      }
    }
  }
`

ContactSante.query = query

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > h2 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 52px;
    margin: 16px 0 24px 0;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 34px;
      margin: 24px 0;
    }
  }

  & > p {
    font-size: 16px;
    margin: 0 0 24px 0;
    max-width: 630px;
  }
`
