import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { Template404DataQuery } from '../../../gatsby-types'
import { getTitle } from '../../utils/prismic'
import Breadcrumb from '../../components/common/breadcrumb'
import Layout from '../../layouts/default'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import Button from '../../components/buttons/main'
import BlogPostLibrary from '../../components/blog-post-library'
import { transformAllBlogPostNode } from '../blog-post/transformer'
import FooterCards from '../../components/common/slices/footer-cards'

import { transform404Node } from './transformer'

interface IProps {
  data: Template404DataQuery
  path?: string
}

export default function NotFound({
  data: {
    prismic404,
    allPrismicBlogPost: { nodes },
  },
  path,
}: IProps) {
  const pageContent = transform404Node(prismic404)
  const blogPostData = transformAllBlogPostNode(nodes)

  const { name, title, text, leftLink, rightLink, banner, subtitle, footerCards, parent } = pageContent

  return (
    <Layout path={path} title={getTitle(name)}>
      <Container>
        <Breadcrumb links={[{ label: parent.name, url: linkGenerator(PrismicDocumentType.HOME) }]} />
        <h2>{title}</h2>
        <p>{text}</p>
        <ButtonsContainer>
          <Button color="primary" variant="outlined" width="auto">
            <Link to={leftLink.url}>{leftLink.label}</Link>
          </Button>
          <Button color="secondary" variant="contained" width="auto">
            <Link to={rightLink.url}>{rightLink.label}</Link>
          </Button>
        </ButtonsContainer>
        <BannerContainer background={banner.url}>
          <div />
        </BannerContainer>
        <h5>{subtitle}</h5>
        <BlogPostLibrary data={blogPostData} />
        <FooterCards content={footerCards} />
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > h2 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 52px;
    margin: 16px 0 24px 0;
    max-width: 1200px;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 34px;
      margin: 24px 0;
    }
  }

  & > p {
    font-size: 16px;
    margin: 0 0 24px 0;
    max-width: 630px;
  }

  & > h5 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 40px;
    max-width: 1200px;

    @media screen and (max-width: 480px) {
      font-size: 24px;
      margin: 24px 0;
    }
  }
`

const ButtonsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 32px 0 120px 0;
  width: 100%;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin: 16px 0;

    & > button {
      margin: 8px 0;
      width: 100%;
    }
  }

  & > button > a {
    align-items: center;
    color: ${({ theme }) => theme.palette.primary.main};
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    padding: 0 32px;

    @media screen and (max-width: 480px) {
      padding: 0;
    }
  }

  & > button:nth-child(2) {
    margin-left: 16px;

    @media screen and (max-width: 480px) {
      margin-left: 0;
    }

    & > a {
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`

const BannerContainer = styled.div<{ background: string }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 680px;
  justify-content: center;
  margin-bottom: 200px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 480px) {
    height: 224px;
    margin-bottom: 32px;
  }

  & > div {
    background: ${({ background }) => `url("${background}") center center`};
    background-size: cover;
    height: 100%;
    position: relative;
    width: 100vw;

    &:after {
      background-image: ${({ theme }) =>
        `linear-gradient(to bottom, ${theme.palette.secondary.main}cc, ${theme.palette.primary.main}cc)`};
      content: '';
      height: 100%;
      left: 0;
      mix-blend-mode: multiply;
      position: absolute;
      top: 0;
      transition: opacity 200ms ease-in;
      width: 100%;
    }

    & > div {
      mix-blend-mode: multiply;
      background-image: ${({ theme }) =>
        `linear-gradient(to bottom, ${theme.palette.secondary.main}cc, ${theme.palette.primary.main}cc)`};
      height: 100%;
      width: 100%;
    }
  }
`
