import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { TemplateCatalogDataQuery } from '../../../gatsby-types'
import { getTitle } from '../../utils/prismic'
import Breadcrumb from '../../components/common/breadcrumb'
import Layout from '../../layouts/default'
// Import NewsLetterForm from '../../components/forms/newsletter'
import BlogPostLibrary from '../../components/blog-post-library'
import { transformAllBlogPostNode } from '../blog-post/transformer'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import LogosList from '../../components/catalog/logos-list'
import { transformSeo } from '../../utils/seo-transformer'

import { transformCatalogNode } from './transformer'

interface IProps {
  data: TemplateCatalogDataQuery
  path?: string
}

export default function Catalog({
  data: {
    prismicCatalog,
    allPrismicBlogPost: { nodes },
  },
  path,
}: IProps) {
  const pageContent = transformCatalogNode(prismicCatalog)
  const blogPostData = transformAllBlogPostNode(nodes)
  const seoContent =
    prismicCatalog && prismicCatalog.data && prismicCatalog.data.seo && prismicCatalog.data.seo[0]
      ? transformSeo(prismicCatalog.data.seo[0])
      : undefined

  const { uid, name, title, logos, parent } = pageContent

  return (
    <Layout path={path} seoContent={seoContent} title={getTitle(name)}>
      <Container>
        <Breadcrumb
          links={[
            { label: parent.name, url: linkGenerator(PrismicDocumentType.HOME) },
            { label: name, url: linkGenerator(PrismicDocumentType.PAGE, uid) },
          ]}
        />
        <h2>{title}</h2>
        <LogosList logos={logos} />
        {/* <NewsLetterForm /> */}
        <BlogPostLibrary toolbar withFilters data={blogPostData} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query TemplateCatalogData($uid: String!) {
    prismicCatalog {
      uid
      data {
        parentdocument {
          uid
          document {
            ... on PrismicHome {
              data {
                name {
                  text
                }
              }
            }
          }
        }
        name {
          text
        }
        title {
          text
        }
        description {
          text
        }
        logos {
          image {
            alt
            url
          }
        }
        seo {
          ... on PrismicCatalogSeoMeta {
            id
            primary {
              metatitle {
                text
              }
              metadecription {
                text
              }
              metaogtype {
                text
              }
              metaogtitle {
                text
              }
              metaogdescription {
                text
              }
              metaogimage {
                url
              }
              metaogurl {
                text
              }
              metaogsitename {
                text
              }
              metatwittercard {
                text
              }
              metatwittertitle {
                text
              }
              metatwitterdescription {
                text
              }
              metatwitterimage {
                url
              }
              canonicallink {
                text
              }
            }
          }
        }
      }
    }
    allPrismicBlogPost(
      sort: { order: DESC, fields: data___postdate }
      filter: { data: { parentdocument: { uid: { eq: $uid } } } }
    ) {
      nodes {
        uid
        data {
          primary
          postcategory
          postsecondarycategories {
            category
          }
          posttemplate
          parentdocument {
            uid
            document {
              ... on PrismicCatalog {
                data {
                  name {
                    text
                  }
                }
              }
            }
          }
          posttitle {
            text
          }
          postdescription {
            html
          }
          postmainimage {
            alt
            url
          }
          postthumbnailimage {
            alt
            url
          }
          postintroductiontext {
            html
          }
          postdate(locale: "fr", formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`

Catalog.query = query

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > h2 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 52px;
    margin: 16px 0 24px 0;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 34px;
      margin: 24px 0;
    }
  }

  & > p {
    font-size: 16px;
    margin: 0 0 24px 0;
    max-width: 630px;
  }
`
