import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { TemplateSimplePageDataQuery } from '../../../gatsby-types'
import { getTitle } from '../../utils/prismic'
import Breadcrumb from '../../components/common/breadcrumb'
import Layout from '../../layouts/default'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import { transformSeo } from '../../utils/seo-transformer'

import { transformSimplePageNode } from './transformer'

interface IProps {
  data: TemplateSimplePageDataQuery
  path?: string
}

export default function SimplePage({ data: { prismicSimplePage }, path }: IProps) {
  const pageContent = transformSimplePageNode(prismicSimplePage)
  const seoContent =
    prismicSimplePage && prismicSimplePage.data && prismicSimplePage.data.seo && prismicSimplePage.data.seo[0]
      ? transformSeo(prismicSimplePage.data.seo[0])
      : undefined

  const { uid, name, title, content, parent } = pageContent

  return (
    <Layout path={path} seoContent={seoContent} title={getTitle(name)}>
      <Container>
        <Breadcrumb
          links={[
            { label: parent.name, url: linkGenerator(PrismicDocumentType.HOME) },
            { label: name, url: linkGenerator(PrismicDocumentType.PAGE, uid) },
          ]}
        />
        <h2>{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query TemplateSimplePageData($uid: String!) {
    prismicSimplePage(uid: { eq: $uid }) {
      uid
      data {
        parentdocument {
          uid
          document {
            ... on PrismicHome {
              data {
                name {
                  text
                }
              }
            }
          }
        }
        name {
          text
        }
        title {
          text
        }
        content {
          html
        }
        seo {
          ... on PrismicSimplePageSeoMeta {
            id
            primary {
              metatitle {
                text
              }
              metadecription {
                text
              }
              metaogtype {
                text
              }
              metaogtitle {
                text
              }
              metaogdescription {
                text
              }
              metaogimage {
                url
              }
              metaogurl {
                text
              }
              metaogsitename {
                text
              }
              metatwittercard {
                text
              }
              metatwittertitle {
                text
              }
              metatwitterdescription {
                text
              }
              metatwitterimage {
                url
              }
              canonicallink {
                text
              }
            }
          }
        }
      }
    }
  }
`

SimplePage.query = query

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > h2 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 52px;
    margin: 16px 0 24px 0;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 34px;
      margin: 24px 0;
    }
  }

  & > div {
    margin: 0 0 24px 0;

    & > p {
      font-size: 16px;
      line-height: 1.78;
      margin: 0;
      min-height: 16px;
      width: 100%;

      @media screen and (max-width: 480px) {
        font-size: 16px;
      }

      & > strong {
        color: ${({ theme }) => theme.palette.secondary.main};
        font-family: 'Proxima Nova Extrabold', sans-serif;
        font-weight: normal;
      }

      & > em {
        font-family: 'Proxima Nova Extrabold', sans-serif;
        font-style: normal;
      }

      & > a {
        color: ${({ theme }) => theme.palette.primary.main};
        text-decoration: underline;

        :hover {
          opacity: 0.7;
        }
      }
    }

    & > h5 {
      color: ${({ theme }) => theme.palette.primary.main};
      font-family: 'Proxima Nova Extrabold', sans-serif;
      font-size: 24px;
      margin-top: 16px;
    }

    & > h6 {
      color: ${({ theme }) => theme.palette.primary.main};
      font-family: 'Proxima Nova Extrabold', sans-serif;
      font-size: 16px;
      margin-top: 16px;
    }

    & > ul {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      justify-content: center;
      line-height: 1.78;
      margin: 0;
      padding: 0 16px;
      width: 100%;

      @media screen and (max-width: 480px) {
        font-size: 16px;
      }

      & > li {
        font-size: 16px;
        margin: 4px 0;

        & > a {
          color: ${({ theme }) => theme.palette.primary.main};
          text-decoration: underline;

          :hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
`
