import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { TemplatePodcastsDataQuery } from '../../../gatsby-types'
import { getTitle } from '../../utils/prismic'
import Breadcrumb from '../../components/common/breadcrumb'
import Layout from '../../layouts/default'
import NewsletterForm from '../../components/forms/newsletter'
import BlogPostLibrary from '../../components/blog-post-library'
import { transformAllBlogPostNode } from '../blog-post/transformer'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import { DisplayMode } from '../../types/custom'
import FadeInSection from '../../components/fade-in'
import { transformSeo } from '../../utils/seo-transformer'

import { transformPodcastsNode } from './transformer'

interface IProps {
  data: TemplatePodcastsDataQuery
  path?: string
}

export default function Podcasts({
  data: {
    prismicPodcasts,
    allPrismicBlogPost: { nodes },
  },
  path,
}: IProps) {
  const pageContent = transformPodcastsNode(prismicPodcasts)
  const blogPostData = transformAllBlogPostNode(nodes)
  const seoContent =
    prismicPodcasts && prismicPodcasts.data && prismicPodcasts.data.seo && prismicPodcasts.data.seo[0]
      ? transformSeo(prismicPodcasts.data.seo[0])
      : undefined

  const { uid, name, title, description, parent } = pageContent

  return (
    <Layout path={path} seoContent={seoContent} title={getTitle(name)}>
      <FadeInSection>
        <Container>
          <Breadcrumb
            links={[
              { label: parent.name, url: linkGenerator(PrismicDocumentType.HOME) },
              { label: name, url: linkGenerator(PrismicDocumentType.PAGE, uid) },
            ]}
          />
          <h2>{title}</h2>
          <p>{description}</p>
          <NewsletterForm customButtonText="S'abonner" />
          <BlogPostLibrary toolbar data={blogPostData} defaultDisplayMode={DisplayMode.LIST} />
        </Container>
      </FadeInSection>
    </Layout>
  )
}

export const query = graphql`
  query TemplatePodcastsData($uid: String!) {
    prismicPodcasts {
      uid
      data {
        parentdocument {
          uid
          document {
            ... on PrismicHome {
              data {
                name {
                  text
                }
              }
            }
          }
        }
        name {
          text
        }
        title {
          text
        }
        description {
          text
        }
        seo {
          ... on PrismicPodcastsSeoMeta {
            id
            primary {
              metatitle {
                text
              }
              metadecription {
                text
              }
              metaogtype {
                text
              }
              metaogtitle {
                text
              }
              metaogdescription {
                text
              }
              metaogimage {
                url
              }
              metaogurl {
                text
              }
              metaogsitename {
                text
              }
              metatwittercard {
                text
              }
              metatwittertitle {
                text
              }
              metatwitterdescription {
                text
              }
              metatwitterimage {
                url
              }
              canonicallink {
                text
              }
            }
          }
        }
      }
    }
    allPrismicBlogPost(
      sort: { order: DESC, fields: data___postdate }
      filter: { data: { parentdocument: { uid: { eq: $uid } } } }
    ) {
      nodes {
        uid
        data {
          postcategory
          postsecondarycategories {
            category
          }
          posttemplate
          parentdocument {
            uid
            document {
              ... on PrismicPodcasts {
                data {
                  name {
                    text
                  }
                }
              }
            }
          }
          posttitle {
            text
          }
          postdescription {
            html
          }
          postmainimage {
            alt
            url
          }
          postthumbnailimage {
            alt
            url
          }
          postintroductiontext {
            html
          }
          postdate(locale: "fr", formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`

Podcasts.query = query

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > h2 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 52px;
    margin: 16px 0 24px 0;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 34px;
      margin: 24px 0;
    }
  }

  & > p {
    font-size: 16px;
    margin: 0 0 24px 0;
    max-width: 630px;
  }
`
